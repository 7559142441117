import { useEffect, useRef, useState } from 'react'

import { Alert, Box, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import type { ConversationPredicate, Inbox } from 'talkjs/all'

import { emailSelector as userEmailSelector } from 'app/login/selectors'

import { AIPromptDialog } from './aiPrompt'
import { AssignConversationDialog } from './assign'
import { InfoDialog } from './info'
import { ResolveConversationDialog } from './resolve'
import { useChatSession } from './session'
import { useGoToAccount } from './useGoToAccount'

interface ChatComponentProps {
  conversationId?: string
  feedFilter?: ConversationPredicate
}

export const ChatComponent = ({
  conversationId,
  feedFilter,
}: ChatComponentProps) => {
  const session = useChatSession()
  const etUserId: string = useSelector(userEmailSelector)

  const chatboxEl = useRef(null)
  const [inbox, setInbox] = useState<Inbox>()

  useEffect(() => {
    if (!session) return
    if (!chatboxEl.current) return

    const inbox = session.createInbox({ selected: null })
    inbox.mount(chatboxEl.current)

    if (conversationId) {
      const conversation = session.getOrCreateConversation(conversationId)
      inbox.select(conversation)
    }

    inbox.onSendMessage((event) => {
      event.override({ custom: { etUserId } })
    })

    setInbox(inbox)

    return () => {
      inbox.destroy()
    }
  }, [etUserId, conversationId, session])

  useEffect(() => {
    if (!inbox) return undefined
    if (!feedFilter) return undefined

    inbox.setFeedFilter(feedFilter)
  }, [inbox, feedFilter])

  useGoToAccount(inbox)

  if (!session)
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        Chat is unavailable right now.
      </Alert>
    )

  return (
    <>
      <ResolveConversationDialog inbox={inbox} />
      <AssignConversationDialog inbox={inbox} />
      <InfoDialog inbox={inbox} />
      <AIPromptDialog inbox={inbox} />
      <Box ref={chatboxEl} sx={{ height: '100%', pt: 2 }} />
    </>
  )
}

export const ChatSkeleton = () => (
  <Skeleton variant="rectangular" sx={{ height: '100%', my: 2 }} />
)
