import { useEffect, useState } from 'react'

import {
  useEtGetConversationAiPromptUsingGET,
  useEtPostConversationAiPromptUsingPOST,
} from '@elder/et-facade-et'
import { enqueueSnackbar } from 'notistack'
import type { Inbox } from 'talkjs/all'

import { getConfig } from 'app/config'

export const useAIPrompt = (inbox?: Inbox) => {
  const aiPromptEnabled = getConfig().featureFlags.includes('AI_PROMPT')
  const [conversationId, setConversationId] = useState<string | null>(null)
  const [aiResponse, setAiResponse] = useState<string | null>(null)

  const { data: aiPrompt, refetch } = useEtGetConversationAiPromptUsingGET(
    conversationId ?? '',
    {
      query: {
        enabled: !!conversationId,
      },
    },
  )

  const { mutate: generateAIResponse, isPending: isGeneratingAIResponse } =
    useEtPostConversationAiPromptUsingPOST({
      mutation: {
        onSuccess: (resp) => {
          if (resp) {
            setAiResponse(resp)
          }
        },
      },
    })

  const handleGenerateAIResponse = (prompt: string) => {
    if (!prompt) return
    generateAIResponse({
      data: {
        edited: aiPrompt?.trim().toLowerCase() !== prompt.trim().toLowerCase(),
        prompt,
      },
    })
  }

  const handleReset = () => {
    setConversationId(null)
    setAiResponse(null)
  }

  useEffect(() => {
    if (!inbox) return

    const sub = inbox.onCustomConversationAction('aiPrompt', (event) => {
      const { conversation } = event

      if (!aiPromptEnabled) {
        enqueueSnackbar('This feature is not available yet', {
          variant: 'info',
        })
        return
      }
      if (!conversation) return
      if (conversation.id !== conversationId) {
        setConversationId(conversation.id)
      } else {
        refetch()
      }
    })

    return () => {
      sub.unsubscribe()
    }
  }, [inbox, refetch, conversationId, aiPromptEnabled])

  return {
    aiResponse,
    isGeneratingAIResponse,
    aiPrompt: conversationId ? aiPrompt : undefined,
    generateAIResponse: handleGenerateAIResponse,
    reset: handleReset,
  }
}
