import type React from 'react'
import type { ReactNode } from 'react'

import { days } from '@elder/common'
import { Stack } from '@mui/material'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'

import type { ScheduledVisitData } from './types'
import { VisitBlock } from './VisitBlock'

interface StyledVisitBlock {
  height: number
  daysDisplayed: number
  to: number
  from: number
  children: ReactNode
}

export const StyledVisitBlock = ({
  height,
  daysDisplayed,
  to,
  from,
  children,
}: StyledVisitBlock) => (
  <Stack
    direction="row"
    spacing={1 / 2}
    sx={{
      px: 1 / 2,
      position: 'absolute',
      alignItems: 'center',
      boxSizing: 'border-box',
      marginLeft: '0.3%',
      height: `${height}px`,
      left: `${(100 * from) / daysDisplayed}%`,
      width: `${(100 * (to - from)) / daysDisplayed - 0.55}%`,
      transition: 'all 0.1s ease',
      '> *': {
        flex: 1,
      },
    }}
  >
    {children}
  </Stack>
)

interface ScheduleWeekBlock {
  block: TimelineBlock<ScheduledVisitData>
  height: number
  daysDisplayed: number
  goToPlacement: (placementId: string) => void
  selectedPlacementId?: string
}

export const ScheduleWeekBlock = ({
  block,
  height,
  daysDisplayed,
  goToPlacement,
  selectedPlacementId,
}: ScheduleWeekBlock) => (
  <StyledVisitBlock
    height={height}
    daysDisplayed={daysDisplayed}
    to={block.to}
    from={block.from}
  >
    {days.map((day) => {
      const visit = block.data.blockVisits[day]
      const { placementId, status } = visit
      const isSelected = placementId && selectedPlacementId === placementId

      return (
        <VisitBlock
          key={crypto.randomUUID()}
          placementId={placementId}
          status={status}
          carer={visit.carer || null}
          goToPlacement={goToPlacement}
          isSelected={isSelected}
        />
      )
    })}
  </StyledVisitBlock>
)
