import type { ReactNode } from 'react'

import { MatchingIcon } from '@elder/common'
import { Done, Person } from '@mui/icons-material'
import { Avatar, Box, Stack, Tooltip } from '@mui/material'

import type { VisitStatus } from 'features/visiting/types'
import { getCarerName } from 'utils/getCarerName'

export type VisitBlockProps = {
  placementId: string
  status: VisitStatus
  carer?: {
    image: string
    name: {
      firstName: string
      lastName: string
    }
  }
  goToPlacement: (placementId: string) => void
  isSelected: boolean
}

export const VisitBlock = ({
  placementId,
  status,
  carer,
  goToPlacement,
  isSelected,
}: VisitBlockProps): ReactNode => (
  <Stack
    key={placementId}
    onClick={() => (placementId ? goToPlacement(placementId) : null)}
    width={`${(1 / 7) * 100}%`}
    height={40}
    justifyContent="center"
    alignItems="center"
    sx={{
      ...style[status],
      borderRadius: 3,
      overflow: 'hidden',
      border: '1px solid',
      cursor: placementId ? 'pointer' : 'auto',
      borderColor: isSelected ? 'primary.main' : 'grey.300',
      boxShadow: isSelected ? '2px 2px 3px #00000066' : 'none',
    }}
    aria-roledescription={placementId && 'button'}
  >
    {status === 'ASSIGNED' && <CarerAvatar carer={carer} />}
    {status === 'SIGNED_OFF' && (
      <Tooltip
        title={carer && getCarerName(carer.name)}
        placement="bottom-start"
      >
        <Box position="relative" sx={{ height: '100%', width: '100%' }}>
          <CarerAvatar carer={carer} />

          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              backgroundColor: 'primary.dark',
              opacity: 0.2,
            }}
          />
          <Done
            sx={{
              color: 'background.paper',
              position: 'absolute',
              width: '50%',
              bottom: 1 / 2,
              right: 1 / 4,
            }}
          />
        </Box>
      </Tooltip>
    )}
    {status === 'MATCHING' && (
      <MatchingIcon
        sx={{
          width: '70%',
          height: '70%',
        }}
      />
    )}
    {status === 'NOT_AVAILABLE' && (
      <Box
        sx={{
          width: 70,
          height: '1px',
          backgroundColor: 'grey.400',
          transform: 'rotate(-45deg)',
        }}
      />
    )}
  </Stack>
)

const CarerAvatar = ({ carer }: { carer: VisitBlockProps['carer'] }) => {
  return carer?.image ? (
    <Avatar
      src={carer.image.replace('{MAX_SIZE}', '70')}
      alt="The profile picture of the assigned carer"
      variant="square"
      sx={{
        width: '100%',
        height: '100%',
        background: 'white',
      }}
    />
  ) : (
    <Person />
  )
}

const style: Record<VisitStatus, object> = {
  SIGNED_OFF: {
    '> * > * > img': {
      filter: 'grayscale(30%)',
    },
  },
  ASSIGNED: {
    '> * > img': {
      filter: 'opacity(60%)',
    },
  },
  MATCHING: {
    background:
      'linear-gradient(331.53deg, rgb(218 222 248) 0%, rgba(252, 250, 255, 0.37) 44.43%,  rgb(249 221 205))',
    backgroundColor: 'white',
  },
  NOT_AVAILABLE: {
    backgroundColor: 'grey.200',
  },
}
