import React from 'react'

import { days, timeSlots } from '@elder/common'
import type { FacadeVisitingPlacement } from '@elder/et-facade-et'
import { etGetVisitingPlacementsForDateRangeUsingPOST as getVisitingPlacements } from '@elder/et-facade-et'
import { WbTwilight, SoupKitchen, BedtimeOutlined } from '@mui/icons-material'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import type { Moment } from 'moment'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'

import { SolutionTimeline } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import * as routes from 'routes'
import { useSolutionContext } from 'routes/account/solutions/contexts/solution'
import { formatLink } from 'utils/links'

import { ScheduleWeekBlock, StyledVisitBlock } from './ScheduleWeekBlock'
import type { ScheduledVisitData, VisitStatus } from './types'

type SchedulingTimelineBlock = TimelineBlock<ScheduledVisitData>

const emptyWeek = days.reduce(
  (acc, day) => {
    acc[day] = { status: 'NOT_AVAILABLE' }
    return acc
  },
  {} as ScheduledVisitData['blockVisits'],
)

const makeScheduleBlocks = (
  visits: FacadeVisitingPlacement[],
  daysDisplayed: number,
  focus: Moment,
) => {
  const scheduleBlocks = {}
  timeSlots.forEach((timeSlot) => {
    const weeklyBlocks: SchedulingTimelineBlock[] = []
    const startOfView = focus.clone().subtract(2, 'week')
    for (let weekId = 0; weekId < daysDisplayed / 7; weekId += 1) {
      const id = `WEEK${weekId}_${timeSlot}`

      const blockStart = startOfView.clone().add(weekId, 'week')
      const blockEnd = blockStart.clone().add(1, 'week')

      const diffFromStartDate = blockStart.diff(startOfView, 'days')
      const from = diffFromStartDate

      const diffToEndDate = blockEnd.diff(startOfView, 'days')
      const to = diffToEndDate

      const visitsToInclude = visits.filter(
        (visit) =>
          visit.timeSlot === timeSlot &&
          isBetween(
            blockStart,
            moment(visit.startDateInclusive),
            visit.endDateExclusive ? moment(visit.endDateExclusive) : undefined,
          ),
      )

      const blockVisits = { ...emptyWeek }
      visitsToInclude?.forEach((visit) => {
        blockVisits[visit.dayOfWeek] = {
          ...visit,
          status: getStatus(visit),
        }
      })

      const block = {
        id,
        from,
        to,
        isInfinite: false,
        startDateInclusive: blockStart,
        endDateExclusive: blockEnd,
        data: { blockVisits },
        colors: {
          default: 'common.white',
          text: 'text.primary',
        },
      }
      weeklyBlocks.push(block)
    }

    scheduleBlocks[timeSlot] = weeklyBlocks
  })
  return scheduleBlocks
}

const getStatus = (visit: FacadeVisitingPlacement): VisitStatus => {
  if (visit.signedOffAt) return 'SIGNED_OFF'
  if (visit.carer) return 'ASSIGNED'
  return 'MATCHING'
}

const isBetween = (
  thisWeek: Moment,
  visitStart: Moment,
  visitEnd?: Moment,
): boolean => {
  if (thisWeek.isSameOrAfter(visitStart, 'day'))
    return visitEnd ? thisWeek.isBefore(visitEnd) : true
  return false
}

const height = 50 // px

type Props = {
  focus: Moment
  daysDisplayed: number
  selectedPlacementId?: string
}

export const SchedulingTimeline = ({
  focus,
  daysDisplayed,
  selectedPlacementId,
}: Props) => {
  const { solution } = useSolutionContext()
  const { accountId } = useParams<{ accountId: string }>()

  const startOfView = focus.clone().subtract(2, 'week').format('YYYY-MM-DD')
  const endOfView = focus.clone().add(2, 'week').format('YYYY-MM-DD')

  const { data, isSuccess } = useQuery({
    queryKey: [solution.id, focus],
    queryFn: () =>
      getVisitingPlacements(solution.id, {
        endMondayExclusive: endOfView,
        startMondayInclusive: startOfView,
      }),
  })

  const { push } = useHistory()
  const goToPlacement = (placementId: string) =>
    push(
      formatLink(routes.placement, {
        accountId,
        solutionId: solution.id,
        placementId,
      }),
    )

  if (!isSuccess || !data) return <Skeleton width="100%" height="180px" />

  const { visits } = data

  const blocks = makeScheduleBlocks(visits, daysDisplayed, focus)

  return (
    <Stack>
      <DaysGuide daysDisplayed={28} />
      <Box>
        {timeSlots.map((timeSlot) => {
          const timeSlotBlocks = blocks[timeSlot]
          return (
            <Box
              key={timeSlot}
              sx={{
                position: 'relative',
                borderTop: '1px solid',
                borderTopColor: 'divider',
              }}
            >
              <TimeSlotIcon timeSlot={timeSlot} height={height} />
              <SolutionTimeline
                height={height}
                blocks={timeSlotBlocks}
                daysDisplayed={daysDisplayed}
                disableStops
                disableDots
              >
                {(blocksInView: SchedulingTimelineBlock[]) =>
                  blocksInView.map((block) => (
                    <ScheduleWeekBlock
                      key={block.id}
                      block={block}
                      height={height}
                      daysDisplayed={daysDisplayed}
                      goToPlacement={goToPlacement}
                      selectedPlacementId={selectedPlacementId}
                    />
                  ))
                }
              </SolutionTimeline>
            </Box>
          )
        })}
      </Box>
    </Stack>
  )
}

const daysGuide = Array.from({ length: 4 }, (_, i) => ({
  from: i * 7,
  to: (i + 1) * 7,
}))

const DaysGuide = ({ daysDisplayed }: { daysDisplayed: number }) => (
  <Stack direction="row" spacing={0} alignItems="center">
    {daysGuide.map(({ to, from }) => (
      <StyledVisitBlock
        key={crypto.randomUUID()}
        height={30}
        daysDisplayed={daysDisplayed}
        to={to}
        from={from}
      >
        {days.map((day) => (
          <Typography
            key={day}
            variant="body2"
            color="text.secondary"
            textAlign="center"
          >
            {day.charAt(0)}
          </Typography>
        ))}
      </StyledVisitBlock>
    ))}
  </Stack>
)

const TimeSlotIcon = ({
  timeSlot,
  height,
}: {
  timeSlot: string
  height: number
}) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      top: '-1px',
      height: `${height + 2}px`,
      width: `${height + 2}px`,
      position: 'absolute',
      left: `-${height + 2}px`,
      border: '1px solid',
      borderRight: 'none',
      ...timeSlotIcons[timeSlot].borders,
      borderColor: 'divider',
    }}
  >
    {timeSlotIcons[timeSlot].icon}
  </Stack>
)

const timeSlotIcons = {
  MORNING: {
    icon: <WbTwilight />,
    borders: {
      borderTopLeftRadius: '10px',
    },
  },
  AFTERNOON: {
    icon: <SoupKitchen />,
    borders: {
      borderTop: 'unset',
      borderBottom: 'unset',
    },
  },
  EVENING: {
    icon: <BedtimeOutlined />,
    borders: {
      borderBottomLeftRadius: '10px',
    },
  },
}
