// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { getCanManagePaymentsBankTransfer, getCanManagePayments } from 'app'
import { getConfig } from 'app/config'
import {
  getPrettyCareTypes,
  getPrettyMedicalConditions,
  getPrettyCustomerChurnReasons,
  getPrettyCustomerChurnOutcomes,
  getPrettyFundingSource,
  getPrettyFundingMethodType,
  getPrettyPlacementRequirement,
  getPrettyGenderPreference,
  getPrettyCustomerLearningResources,
  getCustomerLearningResourcesOptions,
  getPrettyCareRecipientStatus,
} from 'app/referenceData'
import { elderUsersSelector } from 'features/elderUsers/store/selectors'
import messages from 'messages/accountInfo'
import { makeSelectors as makeAutoProfilesSelectors } from 'modules/autocompleteProfiles'
import { makeSelectors as makeButtonSpinnerSelectors } from 'modules/buttonSpinner'
import { makeSelectors as makePageEditSelectors } from 'modules/pageEdit'
import { getPrettyName, prettyBillingCycle } from 'utils/accountDecorators'
import { prettyCompactDate, prettyFullDate } from 'utils/dateDecorators'
import {
  prettyAddressArray,
  prettyPaymentMethod,
  prettyStatus,
} from 'utils/decorators'
import { paymentTypeOptions } from 'utils/selectOptions'

import { NAMESPACE } from './actions'
import { isSavingReadOnlyAccess } from './reducers/index'

const { featureFlags } = getConfig()

export const getAccountState = (state: Object) => state.get(NAMESPACE)
const getAccountStateJs = (state) => getAccountState(state).toJS()

export const pageSelectors = makePageEditSelectors((state) =>
  getAccountState(state).get('account'),
)

export const readOnlyAccessSpinnerSelectors = makeButtonSpinnerSelectors(
  (state) => getAccountState(state).get(isSavingReadOnlyAccess),
)
const getAccountValidationError = (state) =>
  getAccountState(state).get('accountValidationError')

export const getJsAccountSummary = createSelector(
  [pageSelectors.get],
  (accountSummary) => accountSummary.toJS(),
)

export const getAccountManagerOptions = createSelector(
  [elderUsersSelector],
  (accountManagers) => {
    const accountManagerOptions = accountManagers.map(
      ({ firstName, lastName, username }) => ({
        label: getPrettyName({ firstName, lastName }),
        value: username, // username is user email address
      }),
    )
    return accountManagerOptions
  },
)
export const getAccountSummary = createSelector(
  [
    getJsAccountSummary,
    getAccountManagerOptions,
    getPrettyCareTypes,
    getPrettyMedicalConditions,
    getPrettyCustomerChurnReasons,
    getPrettyCustomerChurnOutcomes,
    getPrettyFundingSource,
    getPrettyFundingMethodType,
    getPrettyPlacementRequirement,
    getPrettyGenderPreference,
    getPrettyCustomerLearningResources,
    getCustomerLearningResourcesOptions,
    getPrettyCareRecipientStatus,
  ],
  (
    accountSummary,
    accountManagerOptions,
    prettyCareTypes,
    prettyMedicalConditions,
    prettyCustomerChurn,
    prettyCustomerOutcome,
    prettyFundingSource,
    prettyFundingMethodType,
    prettyPlacementRequirement,
    prettyGenderPreference,
    prettyCustomerLearningResources,
    customerLearningResourcesOptions,
    prettyCareRecipientStatus,
  ) => {
    const archiveScheduled =
      accountSummary.status !== 'ARCHIVED' && !!accountSummary.archiveDate
    const pStatus =
      prettyStatus(accountSummary.status) +
      (archiveScheduled
        ? ` (Will be archived on ${prettyCompactDate(
            accountSummary.archiveDate,
          )})`
        : '')

    const careEnvironment = accountSummary.environment || {}
    const genderPreference = careEnvironment.genderPreference || ''
    const location = careEnvironment.careLocation || {}
    const contacts = accountSummary.contacts || []
    const invoices = accountSummary.invoiceOverview || {}

    const lastInvoiceAmount =
      (invoices.lastInvoiceAmount && invoices.lastInvoiceAmount.displayText) ||
      ''
    const lastInvoiceExpectedPaymentDate = prettyCompactDate(
      invoices.lastInvoiceExpectedPaymentDate,
    )
    const outstandingBalance = invoices.outstandingBalance
      ? invoices.outstandingBalance.displayText
      : ''
    const outstandingBalanceAmount =
      invoices.outstandingBalance && invoices.outstandingBalance.amount
        ? parseFloat(invoices.outstandingBalance.amount)
        : 0.0

    const overdueBalance = invoices.overdueBalance
      ? invoices.overdueBalance.displayText
      : ''
    const overdueBalanceAmount =
      invoices.overdueBalance && invoices.overdueBalance.amount
        ? parseFloat(invoices.overdueBalance.amount)
        : 0.0
    const prettyInvoice = {
      ...invoices,
      outstandingBalance: outstandingBalance || '',
      outstandingBalanceAmount,
      lastInvoice: `${lastInvoiceAmount} / ${lastInvoiceExpectedPaymentDate}`,
      overdueBalance: overdueBalance || '',
      overdueBalanceAmount,
      oldestUnpaid: prettyCompactDate(invoices.oldestUnpaidInvoiceDate),
    }

    const paymentDetails = accountSummary.paymentDetails || {}

    const prettyContacts = contacts.map((contact) => {
      let phoneNumber = ''
      if (contact.phoneNumbers && contact.phoneNumbers.length) {
        phoneNumber = contact.phoneNumbers[0] // eslint-disable-line prefer-destructuring
        if (contact.phoneNumbers.length > 1) {
          phoneNumber = `${phoneNumber} (${
            contact.phoneNumbers.length - 1
          } more)`
        }
      }

      return {
        profile: {
          id: contact.profileId,
          text: contact.name,
        },
        phoneNumber,
        email: contact.email,
        relationship: contact.relationship || '',
        isPrimary:
          contact.roles.indexOf('PRIMARY_POINT_OF_CONTACT') > -1 || false,
        isSecondary:
          contact.roles.indexOf('SECONDARY_POINT_OF_CONTACT') > -1 || false,
        isB2B: contact.roles.indexOf('B2B_CONTACT') > -1 || false,
        isPrimaryBillingContact:
          contact.roles.indexOf('PRIMARY_BILLING_CONTACT') > -1 || false,
        roles: contact.roles,
      }
    })

    let custPaymentConfirmed = false
    if (
      paymentDetails.paymentMethodType &&
      paymentDetails.paymentMethodType.length
    ) {
      custPaymentConfirmed = true
    }

    const safeRecipients = accountSummary.recipients || []

    const atLeastOneRecipientComplete = safeRecipients.some(
      ({ infoComplete }) => infoComplete,
    )

    const recipients = safeRecipients.map(
      ({
        name,
        medicalConditions,
        infoComplete2018,
        infoComplete2022,
        recipientId,
        gender,
        status,
        physicalAbuseRisk,
        verbalAbuseRisk,
      }) => {
        const prettifiedMedicalConditions = medicalConditions.map(
          prettyMedicalConditions,
        )
        const checkForRisks = (physical, verbal) => {
          if (physical && verbal) {
            return 'Physical, Verbal'
          }
          if (physical) {
            return 'Physical'
          }
          if (verbal) {
            return 'Verbal'
          }
          return null
        }
        return {
          name,
          prettyName: getPrettyName(name),
          medicalConditions: prettifiedMedicalConditions,
          infoComplete2018,
          infoComplete2022,
          recipientId,
          gender,
          status,
          prettyStatus: prettyCareRecipientStatus(status),
          risks: checkForRisks(physicalAbuseRisk, verbalAbuseRisk),
        }
      },
    )

    let careInfoPdfMessage = atLeastOneRecipientComplete
      ? ''
      : messages.careRecipient
    let careInfoPdfAvailable = atLeastOneRecipientComplete

    // TODO: update this when accounts can be in "2018 mode"
    if (!careEnvironment.completed) {
      if (atLeastOneRecipientComplete) {
        careInfoPdfMessage = messages.careEnvironment
      } else {
        careInfoPdfMessage = messages.both
      }

      careInfoPdfAvailable = false
    }

    let cardDetails = ''
    if (paymentDetails.cardLast4 && paymentDetails.cardLast4.length) {
      cardDetails = `**** **** **** ${paymentDetails.cardLast4}`
    }

    let prettyBillingCycleType = '(not yet selected)'
    if (paymentDetails.billingCycleType) {
      prettyBillingCycleType = prettyBillingCycle(
        paymentDetails.billingCycleType,
      )

      if (
        paymentDetails.billingCycleType === 'MONTHLY' &&
        paymentDetails.billingDayOfMonth
      ) {
        prettyBillingCycleType = `${prettyBillingCycleType} (Day Of Month: ${paymentDetails.billingDayOfMonth})`
      }
    }

    const prettyPlacementRequirements =
      accountSummary.placementRequirements &&
      accountSummary.placementRequirements.map((req) =>
        prettyPlacementRequirement(req),
      )

    const prettyPaymentDetails = {
      ...paymentDetails,
      billingDayOfMonth: paymentDetails.billingDayOfMonth || 1,
      prettyPaymentMethodType:
        prettyPaymentMethod(paymentDetails.paymentMethodType) ||
        '(not yet selected)',
      prettyBillingCycleType,
      prettyFundingSource:
        prettyFundingSource(paymentDetails.fundingSource) ||
        '(not yet selected)',
      fundingSource: paymentDetails.fundingSource
        ? paymentDetails.fundingSource
        : null,
      fundingLocalAuthority: paymentDetails.fundingLocalAuthority
        ? paymentDetails.fundingLocalAuthority
        : null,
      prettyFundingMethodType:
        prettyFundingMethodType(paymentDetails.fundingMethodType) ||
        '(not yet selected)',
      fundingMethodType: paymentDetails.fundingMethodType
        ? paymentDetails.fundingMethodType
        : null,
      prettyFundingProof: paymentDetails.fundingProof || '(not yet added)',
      fundingProof: paymentDetails.fundingProof
        ? paymentDetails.fundingProof
        : null,
      paymentAssuranceRequired: paymentDetails.paymentAssuranceRequired,
      prettyPaymentAssuranceRequired: paymentDetails.paymentAssuranceRequired
        ? 'Yes'
        : 'No',
      custPaymentConfirmed,
      cardDetails,
    }
    const prettyRecommendedResources = customerLearningResourcesOptions.options
      .map((option) => option.value)
      .filter((option) =>
        (accountSummary.recommendedResources || []).includes(option),
      )
      .map(prettyCustomerLearningResources)

    return {
      ...accountSummary,
      testFlags: accountSummary.testFlags || [],
      accountManagers: accountManagerOptions,
      recommendedResources: accountSummary.recommendedResources || [],
      archiveScheduled,
      prettyStatus: pStatus,
      custPaymentConfirmed,
      prettyCustomerChurn: prettyCustomerChurn(accountSummary.churnReason),
      prettyCustomerOutcome: prettyCustomerOutcome(accountSummary.churnOutcome),
      careInfoPdfAvailable,
      careInfoPdfMessage,
      prettyExpectedStartDate: prettyFullDate(accountSummary.expectedStartDate),
      prettyCareType: prettyCareTypes(accountSummary.careType),
      prettyRecommendedResources,
      paymentDetails: prettyPaymentDetails,
      prettyPlacementRequirements: prettyPlacementRequirements || [],
      recipients,
      careEnvironmentNotes: careEnvironment.internalNotes,
      genderPreference: careEnvironment.genderPreference,
      careLocation: prettyAddressArray(
        location.address1,
        location.address2,
        location.city,
        location.postcode,
      ),
      latLong: location.coordinates,
      postcode: location.postcode,
      contacts: prettyContacts,
      invoiceSummary: prettyInvoice,
      changelog: accountSummary.changelog || {},
      prettyGenderPreference: prettyGenderPreference(genderPreference),
      requiresManualSignOff: careEnvironment.requiresManualSignOff,
      matchingNotes: careEnvironment.matchingNotes,
      difficultMatch: careEnvironment.difficultMatch,
      pricingModel: accountSummary.pricingModel,
    }
  },
)

export const getJsAccountValidationError = createSelector(
  [getAccountValidationError],
  (validationErrors) => ({
    basicAcctInfo: validationErrors.get('basicAcctInfo').toJS(),
    billingCycle: validationErrors.get('billingCycle').toJS(),
    paymentDetails: validationErrors.get('paymentDetails').toJS(),
    funding: validationErrors.get('funding').toJS(),
    updateStatus: validationErrors.get('updateStatus').toJS(),
  }),
)

export const getRecipientRowHeightMultiplier = (
  state: Object,
  index: string,
) => {
  const accountSummary = getJsAccountSummary(state)
  const { recipients } = accountSummary
  return Math.max(recipients[index].medicalConditions.length, 1)
}

export const autocompleteProfilesSelectors = makeAutoProfilesSelectors(
  (state) => getAccountState(state).get('autocompleteProfiles'),
)

export const getRelationshipContacts = (state: Object) =>
  getAccountState(state).get('account').toJS().value.relationshipContacts

export const getAccountManager = (state: Object) => {
  const relationshipContacts = getRelationshipContacts(state)

  return relationshipContacts ? relationshipContacts.accountManager : null
}

export const getSalesExecutive = (state: Object) => {
  const relationshipContacts = getRelationshipContacts(state)

  return relationshipContacts ? relationshipContacts.salesExecutive : null
}

export const getIsUpdatingApplicationQuestion = createSelector(
  [getAccountStateJs],
  (state) => !!state.updatingApplicationQuestion,
)

export const getPaymentTypeOptions = createSelector(
  getCanManagePaymentsBankTransfer,
  getCanManagePayments,
  (canManagePaymentsBankTransfer, canManagePayments) =>
    paymentTypeOptions.filter((paymentTypeOption) => {
      const stripeBankTransferEnabled =
        featureFlags.indexOf('STRIPE_BANK_TRANSFER_ENABLED') >= 0

      switch (paymentTypeOption.value) {
        case 'BANK_TRANSFER':
          return canManagePaymentsBankTransfer
        case 'STRIPE_BANK_TRANSFER':
          return stripeBankTransferEnabled && canManagePaymentsBankTransfer
        case 'DIRECT_DEBIT_MANDATE':
        case 'MANUAL_INVOICING':
          return canManagePayments
        default:
          return true
      }
    }),
)

export const getAccountRegulatoryModel = createSelector(
  [getJsAccountSummary],
  (state) => state.regulatoryModel,
)
