import type {
  ScheduledWeeklyVisitDetails,
  SlotDetails,
  VisitingRegularVisitBlock,
  VisitingScheduledWeeklyVisitBlock,
  VisitingSingleVisit,
} from '@elder/et-facade-et'
import { omit } from 'lodash'
import moment from 'moment'

export interface VisitingData {
  isMatching: boolean
  timing: string
  placement: VisitingPlacement
}

export type VisitStatus =
  | 'SIGNED_OFF'
  | 'ASSIGNED'
  | 'MATCHING'
  | 'NOT_AVAILABLE'

export interface ScheduledVisitData {
  blockVisits: {
    status: VisitStatus
    placementId?: string
    carerImage?: string
  }
}

export interface RegularVisitBlock
  extends Omit<VisitingRegularVisitBlock, 'id'> {
  type: 'regular'
  id: string
}

export interface SingleVisit extends Omit<VisitingSingleVisit, 'id'> {
  type: 'single'
  id: string
}

export type ScheduledVisitDetailsFlat = Omit<
  VisitingScheduledWeeklyVisitBlock['details'],
  'scheduledVisitDetails'
> &
  ScheduledWeeklyVisitDetails['scheduledVisitDetails']

export type ScheduledVisit = Omit<
  VisitingScheduledWeeklyVisitBlock,
  'details'
> & {
  type: 'scheduledWeeklyVisit'
  details: ScheduledVisitDetailsFlat
}

export type VisitingPlacement = RegularVisitBlock | SingleVisit | ScheduledVisit

export type VisitingPlacementType = VisitingPlacement['type']

export const getScheduleWeeklyVisitFromSolution = (
  slot: SlotDetails,
): ScheduledVisit[] => {
  const { scheduledWeeklyVisitDetails } = slot

  return [
    ...scheduledWeeklyVisitDetails.map((visit) => ({
      ...visit,
      type: 'scheduledWeeklyVisit' as const,
      id: visit.id as unknown as string,
      details: {
        ...omit(visit.details, 'scheduledVisitDetails'),
        ...visit.details.scheduledVisitDetails,
      },
    })),
  ]
}

export const getRegularOrSinglePlacementsFromSolution = (
  slot: SlotDetails,
): Array<RegularVisitBlock | SingleVisit> => {
  const { regularVisitBlocks } = slot
  const { singleVisits } = slot

  return [
    ...regularVisitBlocks.map((block) => ({
      ...block,
      type: 'regular' as const,
      id: block.id as unknown as string,
    })),
    ...singleVisits.map((visit) => ({
      ...visit,
      type: 'single' as const,
      id: visit.id as unknown as string,
    })),
  ]
}

export const getVisitingPlacementsFromSolution = (slot: SlotDetails) => [
  ...getScheduleWeeklyVisitFromSolution(slot),
  ...getRegularOrSinglePlacementsFromSolution(slot),
]

const dateFormat = 'll'

export const getRegularVisitingPlacementDateText = (
  placement: RegularVisitBlock,
) => {
  const start = moment(placement.details.startDateInclusive).format(dateFormat)
  const end = placement.details.endDateExclusive
    ? moment(placement.details.endDateExclusive).format(dateFormat)
    : 'Ongoing'
  return `${start} to ${end}`
}

export const getScheduledWeeklyVisitDateText = (placement: ScheduledVisit) => {
  const start = moment(placement.details.startMondayInclusive).format(
    dateFormat,
  )
  const end = placement.details.endMondayExclusive
    ? moment(placement.details.endMondayExclusive).format(dateFormat)
    : 'Ongoing'
  return `Week of ${start} - ${end}`
}

interface PlacementDetailsText {
  descriptionText: string
  dateText: string
  timingText: string
}

export const getFormattedVisitDetails = (
  placement: VisitingPlacement,
): PlacementDetailsText => {
  switch (placement.type) {
    case 'single':
      return {
        descriptionText: 'Single visit',
        dateText: moment(placement.details.date).format('dddd LL'),
        timingText: `${placement.details.durationHours} hours`,
      }
    case 'regular':
      return {
        descriptionText: 'Regular visit block',
        dateText: getRegularVisitingPlacementDateText(placement),
        timingText: `${placement.details.hoursPerWeek} hours`,
      }
    case 'scheduledWeeklyVisit':
      return {
        descriptionText: `${placement.details.dayOfWeek} ${placement.details.visitTimeSlot} visits`,
        dateText: getScheduledWeeklyVisitDateText(placement),
        timingText: `${placement.details.durationHours} hours`,
      }
    default:
      throw new Error('Visiting placement: using incorrect placement type')
  }
}
