import { useEffect, useState } from 'react'

import { CloseableDialog } from '@elder/common'
import { Button, Input, Stack } from '@mui/material'
import type { Inbox } from 'talkjs/all'

import { useAIPrompt } from './useAIPrompt'

interface AIPromptDialogProps {
  inbox?: Inbox
}

export const AIPromptDialog = ({ inbox }: AIPromptDialogProps) => {
  const [open, setOpen] = useState(false)
  const {
    aiPrompt,
    aiResponse,
    isGeneratingAIResponse,
    generateAIResponse,
    reset,
  } = useAIPrompt(inbox)
  const [dirtyAiPrompt, setDirtyAiPrompt] = useState<string | undefined>(
    aiPrompt,
  )

  const handleGenerateAIResponse = () => {
    if (dirtyAiPrompt) {
      generateAIResponse(dirtyAiPrompt)
    }
  }

  const handleReset = () => {
    setDirtyAiPrompt(aiPrompt ?? '')
  }

  const handleCopyResponse = () => {
    if (aiResponse) {
      navigator.clipboard.writeText(aiResponse)
    }
  }

  const handleClose = () => {
    setOpen(false)
    reset()
  }

  useEffect(() => {
    if (aiPrompt) {
      setOpen(true)
      setDirtyAiPrompt(aiPrompt)
    }
  }, [aiPrompt])

  return (
    <CloseableDialog
      title="AI Prompt"
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Stack direction="row" spacing={2}>
        <Stack sx={{ flex: 1 }}>
          <Input
            inputProps={{
              sx: {
                minHeight: 200,
              },
            }}
            value={dirtyAiPrompt}
            onChange={(e) => setDirtyAiPrompt(e.target.value)}
            multiline
            fullWidth
          />
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleGenerateAIResponse}
              variant="contained"
              disabled={!dirtyAiPrompt?.trim() || isGeneratingAIResponse}
              fullWidth
            >
              {isGeneratingAIResponse
                ? 'Generating...'
                : 'Generate AI Response'}
            </Button>
            <Button variant="outlined" fullWidth onClick={handleReset}>
              Reset Prompt
            </Button>
          </Stack>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Input
            placeholder="AI Response will appear here"
            inputProps={{
              sx: {
                minHeight: 200,
              },
            }}
            value={aiResponse}
            multiline
            fullWidth
            disabled
          />
          <Button
            onClick={handleCopyResponse}
            variant="contained"
            fullWidth
            disabled={!aiResponse}
          >
            Copy Response
          </Button>
        </Stack>
      </Stack>
    </CloseableDialog>
  )
}
